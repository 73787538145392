import React, { Component, Suspense } from 'react'
import Cookies from 'js-cookie'
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
//const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
// Email App
const isAuthenticated = () => {
	if (!Cookies.get('user')) {
		return false
	}
	//write your condition here
	const user = JSON.parse(Cookies.get('user'))
	// Role is important 	
	if (user && user.Token && user.Role) {
		return true
	} else {
		return false
	}
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)}
	/>
)
class App extends Component {
	render() {
		return (
			<BrowserRouter >
				<Suspense fallback={<CSpinner color="primary" />}>
					<Switch>
						<Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
						{/* <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            /> */}
						<Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
						<Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
						<Route exact path="/401" name="Page 401" render={(props) => <Page401 {...props} />} />
						<Route exact path="/403" name="Page 403" render={(props) => <Page403 {...props} />} />

						<AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
					</Switch>
				</Suspense>
			</BrowserRouter >
		)
	}
}

export default App
